
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HeaderTitle from './HeaderTitle.vue';
import ActionButton from './ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from '@/main';
import { avatarColor } from '@/services/configs';
import { Title } from 'chart.js';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class VerticalMenuBar extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isEmbeddedMode!: boolean;
  public mini: boolean = true;
  public search: string = '';
  public menuPurpose: string = '';
  public canExpandOnHover: boolean = true;

  public get getMenuItems() {
    //check if the store is free package
    if (this.store.currentPackage && this.store.currentPackage.name === 'Free') {
      return this.freeMenu;
    }
    const storeId: number = this.store.id;
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      return this.replenishmentMenu;
    }
    if (storeId > 9683) {
      return this.replenishmentMenu;
    }
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      this.menuPurpose = 'replenishment';
    }
    if (this.menuPurpose === 'replenishment') {
      return this.replenishmentMenu;
    }
    return this.FullMenu;
  }
  public pinMenu() {
    EventBus.$emit('pin-menu', this.canExpandOnHover);
    this.canExpandOnHover = !this.canExpandOnHover;
  }
  public replenishmentMenu: any[] = [
    {
      section: 'replenishment',
      title: 'Replenishment',
      items: [
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Top overstocked products',
              link: '/reports/38/demand-forecasting/609?report_query=eJzNmPFr6zYQx%2F8VYxi8sfjNceqkzW95HYOydeuWtPAIwSi2mmpPtjxLzhZK%2FvedbMc6K06bsPfDg1Kw%2FNHd6U4nfZ1XVzHFqTt1FyJ3xJYWUon4C02cvBBJGSvpDtz4hRRqscs1Nn%2B8v5%2F9%2BRlGX4i81S%2FcqSpKOnDli%2FinGXgmXOoRymmsaHJLiuRW8DLNpDtdvroZSbWpJ1IwkintQCR6YNsOJESRxqGi%2F%2BoRXjJ5T%2FKcZRt3mpWc7wetoZ9FQWMilTMnnErnjxKMMLUzhpfPDRFJTUR%2FN8Sq6yqhMUsJt725J2a7KISFUIQ7jxlT0pkLniDfpR6MJAxGLNvSTIliF%2BWcZBlYPzOCN224%2B9XATSmRZaFtGOAQt2bADUtpJpnIgFnvIpNsqaBokWLVQgI%2FuPL8CfzBG5olnfGh7%2FljbYo%2Bk5KrqCDZRr8cpTD4zLiiBS5wW5ffzb76EZKUmF32Vq30boxKTUcVrnO1JbwEaun6Lixa5LQgsFSYtlH9mdS5ydlWgLlc1YsHMCcFSWVvrAtYr%2FMhITv5vQmqjanKBgpjVMVRPTaOJWSb024wWZmuITVQVUGSqqJVh6D9M0v%2BKiW0irMoIOnGcb3jVDPYuh3aXjmT6pRPePWk0Xq9LHGn1wP3V7KmHLAfhr7%2FHVBPtWk3%2BOhX%2B1pjNwi76VDDjzctNfQRdm1h1wYbImxiYRODBQgbW9jYYCOEhRYWGuwKYVcWdmWwEGEjCxsZbIywwMICg0066e1iQ4PhKnQpw6ASeF1TPipBgErgBRZmShCgEngjCzMlCFAJvCsLMyUIUAm80MJMCQJUAm9sYaYEASqBN7EwU4IAlcC7tjBTggCVwLuxsGF1KJxsxTncYw7chqYJlcgz3H0zzs%2Fp%2BsPNddx%2FIVpFiGIzWQvRAoY%2B3humPUO0gQLMBIhBGyjETGiYMdo9emkG0k89uVqZqz0qaC7g9oiPr%2FeHWkCYNN4pmi71P%2BcnKuOCVefxqj9nndsPbqtGjeAr96vJh%2Fkvj8ZI4yiSX8qLIqsmfFuaxAphlooSp8sOgFTvLfdxWcDdE%2B%2Fe819P7vV%2Bd9AjbRKc3yjFCqlXsZyZh3610xfH1xMhl5THnv%2FtiEYkPrYbFIUDosoB%2FYOiIdtNpK39z4DeM9OJ6SCI3gkMBOCGRglhfBehBSPddHZw75vCAbabWWTOC8GabTmLVQmFPd74Z4Zycj72f5fFIgXeYJ2Oql9eeqoczevtpEUtgo96RYtjy5FRof3tUenpt0%2BN6jKyj4zWgNlF1XV88fFlzcex6J3n3AqprL6Ey06efU62E7DlOeVcl%2B6hYDHOZGf8TA%2FWZYbaZb1z5pUskf0HS61ZLj9MGq1z0uknOO5OOl1XLy93Ws%2BrP%2Bd0m9ZfcyA4XqtnkMoDl4G2kA%2B0eKgGQpA1ErTJp53WbG%2Bd6Ksa1KoEUP1rBoxsClHm1dzDQ%2FNef2zD0TQX6JeOFL6GGRrZw9oJj0tOtEZKqYKCynouNBh8fTIpMjhqFMT5ut%2F%2FB7IlzzA%3D',
            },
            {
              title: 'Top understocked products',
              link: '/reports/38/demand-forecasting/609?report_query=eJzNmPFr6zYQx%2F8VYxi8sfjNceq0zW99HYOydeuWtPAIxai2mmpPtjxJzhZK%2F%2FedZCc6q06bsPfDg1Kw%2FNHd6U4nfZ3nUDPNaTgLF6IObquCymCuRf4luCOSkUqrcBTmT0TqxaY22Pz2%2Bvriz88w%2BkTUpXkRzrRs6ChUT%2BKfbuCRcGVGKKe5psUlkcWl4E1ZqXC2fA4rUhpTnQfjQBRmYL0bKIgmnUNN%2FzUjvGHqmtQ1q1bhrGo4fxntDP0sJM2J0sGccKqCPxowwvTGGV4%2BdkSmDJH93RH3fVcFzVlJuO8t3DM7RCEshCYc8se0CuaCF8h3YwYzBYMZq9a00kJuspqTqgLrB0bwpo3w5X4UlpSoRhobDtjGbRhww0paKSYqYB42mUu20lC0TDO7kCROTqL4FP7gDa2K3vg4juKpMUUfScN1Jkm1Mi8nJQw%2BMq6pxAXe1eX3NZXK7Cpa%2FNhusu7pzVoJmJU1hs4sbnK1JrwBahnGISxa1FQSWCpM43o4kyY3NVsLMFfrdvEA1kSSUg3GuoD1Bh8KslHfu6B2MdlsoDAmNg772DlWkG1O%2B8FUTfkAqYGqClLYitoOQfvnovirUdAqwUJC0p3jdsfpbnDndux75UzpfT7h1Z1B2%2FWyIpydjcJfyQPlgP0wjuPvgLprTYfJx9jua4OdI%2By8R40%2Fnu%2BocYywMw87c9gYYaceduqwBGFTD5s6bIKw1MNSh50g7MTDThyWImziYROHTRGWeFjisNNeevvY2GG4Cn3KMagEUd9UjEqQoBJEiYe5EiSoBNHEw1wJElSC6MTDXAkSVIIo9TBXggSVIJp6mCtBgkoQnXqYK0GCShCdeZgrQYJKEJ172NgeCntbcQ73WAC3oWtCLeoKd98F54d0%2Ffbmet1%2FKVpFimJzWUvRAsYx3huuPVO0gRLMJIhBGyjFTOqYKdo9ZmkOMk8Dubp3V3smaS3g9shfX%2B83UhRNjq73K03LpfkX%2FERVLpk9j%2B%2BHc9a7%2FeC2qjtjqE5fTT7Mf7l1RjpHmfrSHBWZnfBtaRIvhItSNDhdfgDEvvfc542EuyffvOe%2FnTzo%2FWqrR3ZJCH6jFCukQcVyYB6G1c5QHF9PhBxTHn%2F%2BtyMakfhYr1AUAYiqAPQPioasV5mx9j8Des9ML6atIHonMBCAK5oVhPFNhhaMdNPBwb1vCge428yiCp4I1mzLi1w3UNjXG%2F%2FAUPbOx%2F6vqlyUwDus11Hty2NPlVfzBjtp0YrgV71ixLHnyKnQ4fawevrtU8NeRv6RsTPgdpG9jo8%2Bvrz5OBaz84JLobTXl3DZqYPPyd0EbHlOOTelu5Esx5nsjR%2FowbvMULs8bIK5lSVq%2BGBpNcvxh0mndfY6%2FQTH3V6nD%2Fbl8U7bee3nnGnT9msOBMezfQapPAoZaAt1Q%2BWNHUhB1ijQJp82RrO9daLft6BRJYBajQNDKyma2k7ePnSA%2BdqGs2ku0E8dJXwOMzTyAosnPG84MSKppBoqqtq50GHw%2BcmUqOCs0RDo88vLf48Gzs0%3D',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-clock-outline',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672?report_query=eJztWf9r4zYU%2F1eMYbCx%2BHDcJG3yW9duMHa3dddeYZRiVFtptZMtnyTnGkr%2F9z3JSfSsOFmSwr5Afgn46fO%2BS3pPLy%2BhZprTcBL%2BXM5oqYWcBx9pxWnJ1FMBhLAXZk9E6pt5ZVDw%2BUTUhaGEEy1r2gvVk%2Fi6IEwJV4ZCOc00zS%2BIzC8Er4tShZO7l7AkhZFxJUVeZ1ayyK1qTYs78xNcUpVJVmkmyntYz4kmC8WaPhsOXjP1gVQVKx%2BB%2BDBPq4Ww195K%2Fi2RjJRI%2FmxF%2BDuBSySSdv3LJydpoS1Vn%2Bu9zLMMSOhHKmROZfB7DdqYnjsNd2yZh1TiPHjhyGnGCsLXVG7g7tJ9STRFemVDTkEJ9ZU1yLYmjMfiP5VMq%2BBa8BwJV%2FCZflk4m1ZEkmJHhzo417UZUFCBTznBoSQzKskjBRMZn6e1gaZW3j4GbBeCbTnP%2F6wVbPugPsgoRThVqZa0zN9qGhKFDXxPSR7csIIG34JN6jtkFeyaXMiUAyLVgNjRAp%2BtdXDIlOp5cK1F9hlvBUtOlSED44zyXXfCGmPXrr4SDB%2F91bauDH1HTS0erMTdkR0H9zzTNeHBOmZHrRv52xZkogB8983RrKXuEviynwlbJGAjLmHzBGIa4JJREFYaISjRNlFmoyFxFSelwe2a820ysEkfQHtRF13RcxaBa4fHppu5ZQN53sEG8vwGGzqZu%2BJgT11wIWb2op8rLwpNXDOzbKO7Rwx81q4IbNMOLhyqvYO1y%2FffNpVWY35zsg%2FIfZuxy%2BvNesHww%2FSuMWK9P7FnKDYbtU7N8mF6u1i7LtuLeca7eojM0Pe8bC2PX9phDyl8my8rnql1sAuUf6NntYSil803lsoVY%2Fh63wsLSlQt6Za%2BCaRDWSsVNKRNQ%2Bd6SaWh522q3iRM4mQQxadRPIAVKIotej%2BO4hMjik5JzXUqSfloFk8KIE4Z11RCfwz2VGwmdCps%2F9s027bJaHXPqy7jxpb3VWxwze9BI8troN6F%2FfB%2B8bUIEWfK64TLungAC3p26dZAG4UsDydnvfA9eYBSOwm%2F78fxN4C6bUSHybvYpsvAxgg2bqH678YrVD9GsDMPduZgfQQ79WCnDpYg2MiDjRzsBMGGHmzoYAMEG3iwgYMNEezEg5042AjBEg%2BWONhpK7xtWN%2FBcBbaKIdBKYjaomKUggSlIEo8mEtBglIQnXgwl4IEpSAaeDCXggSlIBp6MJeCBKUgGnkwl4IEpSA69WAuBQlKQXTmwVwKEpSCaOzB%2BvaWUEJqe8sCbRCbcyJIbq8V%2B95FV9aPzxmvzVIgah2JaWTLVZC3SiAspWKaotaGLtnwwS2Ff3IVIOx12%2FHuXD%2B5I5TCXwVyCwQvnR%2Bh%2FP1BFQLN4cv3fTze5vv1k%2Fga3IjKOapFVWKPzjl%2Fm0tDlNIhMtZtoSHKZj%2FGB8XdVUN0mhKMSRAGnaYhxgwdZoSOknHNgczXevDGXfG7d9MSU3mAAarTcWKyPjH5959eqzfO%2Buv5H33qHGdHx9nRcXb0P5wd%2FQcmN8cxyXFMchyTHMck%2B41JKkNqphLQlr7Yb3hx9kIGHai6ovLKEoZx0%2FL%2BMDfd%2FuZmZNEZm84VgOZPRKA8SlFXlnP5sVg3YxoojdcC%2FcFY1FwzRHntuT6TPhotqenQwZ6M8KzmxLTXBdWSZaoRCbcl1GCmRNk0IZOX19e%2FAMDo40Q%3D',
            },
            {
              title: 'Replenish from warehouse',
              link: '/reports/measures/inventory_transfer_suggestion?report_query=eJztWMlu2zAQ%2FRVBpwbwoT304lvjokDQBW6W9hAEwoQay0QoUeDi1jD87x1SlE3Hm6wcetHFEGf43jxRM9KDV6nhRmA6Tn%2BDwrm0GpNbrAVWXM9LrAytmCzpKgfDZaXTUcrmoMz9snYoWs5BT1wkHRtlcZTqufwTAjMQ2kVQIDOYT0DlEylsSTTjx1VaQek4pkrmlhnHLHMXuDFYPrqf5DNqpnjtKj9RnjRAKGzwr0MIy%2FV3qGteFRR8XmZ1IFuPNvy%2FQHGoIv7FJnCOsN0Zsd19fdgyhWqZfrEd2OLdEeM1KE%2B3x%2Fq8SXRlbhERezjd5M6AsfqA9Dbe%2FXBbzIEqnmCvhmmiF1TwiPgZUgNKtWVu1skPl%2B1EvGgIIsqHiptkIvV%2BY2SWUhlrUhE3s0phxZbH2iTCxQ2DQtC2ZKo4i87G761DrEuRCBCx38vkm2R%2BNtvTCAUejcxESGVud5cJeo2JS31aABfwLDD5aeluuVkm76LyV0dKNzdkMl7RMzBSLV%2FpyJHxEsRJKXscsa6bit5Q7oQvksUD6k269kliYV%2BkQgaaZg8E6k6qZgGSaQfpoWiXYLffc1TaSPaCeeKav5si62C%2Bs%2FvIidCxllssgVfumfVUpVqC3speMew8NyXLo1M1o%2BTlc7WPOjdZOyKujgroO11nWM7MV0dxvWfsHM2pKeuordeknaLYeSsrqPQMlfIPNbR1R2EmYB30ou4%2BR3N4AN8itd8UnuQ4dIqNqvhNEBKXDf8OKF0%2FjdISQVvlQJvO2pxbpm1RoHYaHT8nC6zddeMmtg4SZ2CFyQhVBEM848KgIodLFWq%2BkCaT3sH6bzjt5xXmtK0GBaVutrUOmU6jlsp5iMElDy55cMmDSx5c8uCSB5c8uOTBJQ8u%2BT%2B55BoKDBaWHOnKr9Pxh1HKyXzqKaqpD3x8Tz6WzOv1MnhaunbOlFb%2Bf2AKFUraus37RdjgvLjV5k5G%2FxqXZKt5FFmPtm4Mi9IPYp6OqSoDwawA559LNPTtD66a5oFMNtd0fnTnJHG1Xv8DZjwqYA%3D%3D',
            },
            {
              title: 'Replenishment params',
              link: '/settings/inventory-replenishment-parameters',
            },
            {
              title: 'Incoming inventory',
              link: '/settings/incoming-inventory',
            },

          ],
        },
        {
          title: 'Replenishment by model',
          icon: 'mdi-details',
          type: 'group',
          items: [
            {
              title: 'Just-in-Time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/672?report_query=eJztWF9v2zYQ%2FyqCgAEbZhWWEjm239IEA4a1WNq4eQkMgZHohCslqiTlRQjy3XekHPNEy5njvgxYXwTo%2BLs%2FvDveHfkUaqY5Defh79WaVlrINvhMa04rph5KIAQrIYP3TVVwGlxJUTS5VuEozB%2BI1Iu2Npzw%2B0DUhaGEcy0bOgrVg%2Fh7Q1gRrgyFcpprWlwQWVwI3pSVCue3T2FFSiNjI9pIFoU1R9Py1nyCS6pyyWrNRLWE9YJoslGs6aPh4A1TH0lds%2BoeiHdtVm%2BEPY%2B28m%2BIZKRC8tdbwr8JfEEiadd%2FfHGSNtoy9bU5QBpGI4mfqZAFlcGnBlQx3Trxt%2BwlMJnEgfF8UdCclYTv6NvDPaT7kmiK9MqOnIES6ivrkH1NGI%2FFf6mYVsG14AUSruA3%2B7bZbFYTScoDNzTAuavNgIIa9lQQ7EqyppLcUzCR8TZrDDSz8t5iwOtCsC3nxV%2BNgpwPmqOMUoRTlWlJq%2BJ7TUOisIEfKCmCBStp8DPYpH5BVkHWFEJmHBCZBsSBFvhsvVNDVlS3wbUW%2BVecCpacKUMGxjXlh2bCDuNQVl8Jhs%2F9Nq1rQz9QU48HK3FFc%2BDgnue6ITzYxRyodS9%2F34JclIAfrhzdWuaKwLe3mfCKBGzEJSRPIFYB7iElYZURggJtA2USDYmrOakM7tCYvyYDm%2FQRtJdNOeQ9ZxFs7XjfDDP3bCCPB9hAHr%2FDhkHmIT%2FYUxdciLUt9K3yvND5NTfL1rtv8IHPOuSB17TDFo7VPsA6tPc%2F97VWY353so%2BIfZ9xaNf79YLhx%2BndYcR6f2OP0Gz2al2Z5eP0DrEOFduLNudDM0Ru6G8stpbHb%2B2QQwpX85eOZ3odZIHyK3reSGh6ebu3VW4Zw%2BflKCwpUY2kr8xNIB3aWqVgGu2GTTdIKg0Db9f15mEyTk6j8SSKp7ACTbFPn0XxmRFFV6ThOpOkujeLJyUQV4xrKmE4BntqthY6E3b47SZtO2T0RuftlLGw7X3rG9zzRzDF8gaot2EcLjd%2FGxdxprwxuGrKO7BgZJduDLRTyIpwPh2FH8gdtNp5%2BGs8Hv8EqJtOdJi8G9twGdgMwWY9VPxutkXFYwSberCpg8UIdubBzhwsQbCJB5s42AmCpR4sdbBTBDv1YKcOliLYiQc7cbAJgiUeLHGws557%2B7DYwXAU%2BiiHQSGI%2BqLGKAQJCkGUeDAXggSFIDrxYC4ECQpBdOrBXAgSFIIo9WAuBAkKQTTxYC4ECQpBdObBXAgSFIJo6sFcCBIUgmjmwWJbJbggha0i9m6LJ1y49gYLUbtDqEVd4dN3zrl%2F%2FhSIskVz4Oq4e%2F5StIsU2ea8lqINxGOcG%2B54piiBEoxJEAYlUIoxqcNMUPaYrTmQ%2BRvw1dK9BJjCKqQpvj9eA%2F6PrwHBHdwFbe7jcRBfWu3igTvb4duvtHvNwkr7bwp3dv2o54iOFateCA13uB9vIP%2BxNxAzWxlUN1pB8Xmy%2F9A2RyGDOqOuqLyyhBRKnII69b419Xs4aMsOYmoTgMwTKFDupWhqy%2FXys1k3cyb45Fqg59ESxkCGKM8jVxvovTmjmam3YEtOeN5wYgpoSbVkuepEwkUdrGFKVN2Zmz89P%2F8D%2BNNcDQ%3D%3D',
            },
          ],
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      icon: 'mdi-chart-bar',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31?report_query=eJztWEtPGzEQ%2FiurPRUpB0AKbXOD9FBUqGgD9BBFq4nXJBZee%2FEjaYTy3zvep5PdhKVV4ZILwuNvvhmPZ2fGeQ4NM5yGg3BkJHkMpAjmIOJgugpulIwtMcE9KAbChL2QzEGZ21Xq4Licgx46STgwytJeqOdyWQgegGsnoZwSQ%2BMhqHgouU2EDgfj51BA4jgKC45Zxk5waWgydn%2BCL1QTxVLDpJjgfgwGCsOG%2FnYa3DJ9DWnKxAyF01WUFmTrXsXveZ7zLyrBS4Ql0mMbfburmQprkX60Hdh8tMd4ASqja7BOq42uzKWGx17e38iAsbrF9VLePbilTouVjKBhw%2BTSV1jINPw7pCKWqmbO18F3t9uJeJETeJR3gplgKHUzMSKLWxHJtzxuYpWigqx2pYmn5ycM5Rxh%2BCEx4sUmw6aFrIsRT8FjvxR4MiPVKvhh0QVmVrWJ8TkxFnjQxGx9TDElLAHeMLlTf9MDIhN3wBYHWLEXPb3OcEPPNziUScIM1pM2i6TcfK3JpmJ7mO%2BBW7ovxi6lJh3vtFXZt%2FuTGmC8YdQXd7W1QbU3g4Ibqgj1KyZeZAGqohOlOWjLfFqpbt%2FoLoI9Yd7ryMIh%2FtqLDW3fhWu8BKuNTIIrScB1nuBD0%2Fmj2qUSNi7%2FmUQpW0gT7fn8CgSaj9rsYRNWtFofdUvgN%2FDDr2pzmQb8TSK0Zeo9gvOCC35chFwGQ%2FcR%2FQJF59Jq%2Bp%2Bj02bwXWLUxZH1pBcmFLRVdG9%2FQYdZQoVGpaJ%2FVzMbCOArw4gujnOLV%2FOVzeZOhz6A5SZSIGbFdPrAuKGqZdx8eSDKSgRqhkAMW9AQXZd4UEBPEU6fWgYPd7w8IjIbWlEeKbnUUUtsieR6X2iRPgUFycasjIm4DPDEtdtGpsJ39pxz52m2LlzTeL98x5zEtMlKbW6FxeGgf9YLr2BKOaL6CMkbhvvfpXmG%2BFgjTo49CC4qzKcac%2BpjTj3MZ8%2BSj%2BnXmLPjGuOOVoPcysWbS4izBM5eG05SvjciRVOp3ER2eHNsvTne5lVwGE4Pw%2BlhOP3n4dQ1FZjRoqdgHXvO1uHgpBcyLFkaPbnJBH2slhpL3sXKtYJ9n9gkB7pih1D3yw1KZkraNNMtF8W%2B69o4HI6k96tOgp2WeZJ1r64edIbN20SugKNHBDixHFz6JtTgC1bnlJjV2OGYliLC8KD7z%2Bv1Hx53hc4%3D',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276?report_query=eJzdWE1v2zgQ%2FSuETlvAAeykcWLfUvdSNF1km7Z7MAyCkWibKCWq%2FHDWCPzfd0aftKXIcoGkRS9BNHzz3mgozgz9FFhhJQ%2Bmwa16JPdWhd%2FJLd9wSe60ilxoTTAIwjXT9ss2RRg8rpmZoSWYWu34IDBr9VgYlkwatHDJQ8ujGdPRTEkXJyaYzp%2BChMXIUVAjs4rQ8MHyeI5%2FyHtuQi1SK1SygPWIWVYIW%2F4fekgnzCeWpiJZgfFhS9OCbDeo%2BL8xLVji8W8qwzHCEumx3X%2F8WjMVatR8dz3YfLTH%2BI7pjK7B%2BlAt9GUuPTz2Iruwmcw60xJ6ae%2Bf3NKnRSUjaGjY3HqCQubh7yFPIqVr5vyZ%2FI2rvYg3OYFH%2BTURlsyUaX4Y1MESDfMljzt0WvMk3D73mXh%2B%2FgfDpQQYnCARernJsGlh6yPiOXjsHxJ4M6v0lvzjIARht7XE%2FCa0jknSxBwcpoiHImayIfms%2F34EoYrxBVsCEMUa%2FXGacMPPF5ypOBYW6kmbYlgunirZdGxP8zcmHe%2FKMX5Si5572urs637mlgnZEPXNfbX2qDq%2FIHLHdcj9igkbWYCq7NA0Bx3Ip5Xr4Y4%2BR9CR5s5ANoj46Sj2vP0QPsEmOGNVTG5VyLDzkL%2Bawb%2BpQyph8%2FKfBU3FRlnacfwKBMjTNj1ovJpXz2%2F6fcCvEIdf1dYqJfJVMnQg9SuScyQEPy8JDE4zPET%2FMs3Xyhn%2BwtlpE%2FwlOeoTyG4xCGLOjNNZJGxrqFpS49JUYsWKRMwTA8j9YQ5GSAsDJbUiy%2FH58Pzt2fD6bHQNK9DX9%2B2Ts9EYqfiSOWmpZskKFy%2BGEViXQlqu%2FenzPcRA1NJ7uc88ZiLBnNRlx%2BAoTDFerDRZ8QCS4CqA91Hw9gw8AQfk%2B7lOXPzAG2OHIfdKRkQyY8lkSJDW08JGb6gBBEUEnQyjPdHhgejqYEwh5QZjsvP9UdkInc%2FrKdMs3hvA4et%2BJF9UWsdgVZr4kjdSomj2XKgYSJB8ZvgSxmb1O1cRUTC9HA%2BCW%2FbAJaAuAZJ3IfwfU5MhrmrEaOhB4KHCXNeYcx9z7mEmnpKPuawx42GNwVerQfiEaZOKRdmpyK4waCkvMVTzVGkc8179IlMO2eZFR%2FnyQFRFi%2BijB6JHdTk467%2FnCFvBXnqGvdnA8V1x4lUDONBYCTxltllBhoWEtgHjEhaEntK1Y11LumrQ1bESNHrsqVwNd75vl%2FTF0fJ3Mez72i2eXdLjo9LjhnRRz48pj7uVe9f805UnTeWDS%2B68uqX2vTmccq2d79l7KiROyj%2FqspW9EHZfOONF84VO8ZQ9B9PRIBDQFAzcbu4ywyX0IwNN5d0Wm23ruV%2FkCOwjgMFf2sCy0sqlmVP5UKzjlAXD%2FL3yfoWLYRoSnmU3qBsHX8HcZSn2RgglZDJ0kmGzi7mFfTQ5JdQzGB6EUQmEB5PO9Gm3%2Bx8WhAmv',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288?report_query=eJztWG1v2zYQ%2FisEP62AAzjZkiX%2B1noFFqxdszrtMASBwFCMTYQiFb44NQL%2F9x4pSqIt2VEKLAXW5UNgHp%2B753gk7456xJZbwfAEf3AWqVs0s4reoQutckct%2Bkw0J9IaPMJ0QbS9XJUeC8MFMVMvwROrHRths1APUXBLhPESJhi1LJ8SnU%2BVcIU0eHL1iCUpvI1I4S2r3AvOLSuu%2FD%2F0GzNU89JyJa9hPieWRGLLvngN4bh5T8qSyzkIb1ZZGY2tR4396Hprf9kInjJYIxNrsz8%2BtZYiW2bu3ABrKTqx%2BIboYK5j9aaZGGq51kis1xs4s8Q60%2BN6LR8e3FqnhyUY6HDYSvoMhqCR7iGTudKt5WqM%2FvSzgwwvKwOJyU%2BSWzRVpnswMgdTGa2mEtvUac0kXe06JoleemCYEACDm8RpEpuALaNsCEmikFg%2Fl7Ayq%2FQK%2FeXABW5XLcXVa2odEaiL2bpMOaO8IKJDuVN%2F0wOqCr%2FAHgd4nMvun0fc0UsJp6oouIV80sdI68nnUnYV%2B8P8mQjH9sXYH6nrgXvaq5zyfmSWcNEhTcVDuTZM7T1B6IJpytKMCRsZQU10srICbdGXjer2ju4ysCfMex1ZesQ3e7GhnbrwHjbBGasK9E5R4isP%2Bqnr%2FKvWpRp2Vf%2B4zkq%2BVDbbc%2F0iAuizPj4ovZo141fDDvAL%2BJFmtYUqkXiRCG1RfY%2FgPOFCGhepHtDUX6K%2FiWYL5Qz7l6PTR%2FhdYjTEkfX1CBeMGKfZ3voCDvOCSQNKsX43PRuRRKwspyYu5xK25nc%2BX8AU9CUaegceduJofPTLwfj04PAUZqD6b8rPDg5PPAu7JU7YTBM595M%2Fj3OQ3nJhmU571NbF%2B%2BEVNuQYsILHGJatIEgEMKAG1vu3pDcVXmpC7%2FwWNbTxPEEOte1cw%2FbP29kWH7vvaZK%2BpUdsOAi1fMkG0QCmOiQq9PHVo6EkmhQbrwC4Yg8I9rJlt6qUKedrITxhGEcGA0sXOzpAbmwoIhULz%2FHk%2BGSE35EbJgB1DJCqFPrfPhYB8WuLOBwnEBg0mNMWc5RijhLMWcKUYo5bzMm4xfiltSA%2F8mEzStsPOvcnEZ%2F5P78sRfJwXcPbKqDi6yrTrAQF6D93vbDQeUHmSRvB4zAJXi3aKptB2j0wP%2ByT7WUeVc9OAU%2Bx9Sht9NWQuSR0XSa%2BWB4WTCJVfQ0w%2FmtAS08jNovYDGBZjXnKjT3K%2F79s%2FtsvG18MIJfEWgBJ6jGM8eRwhDmkDwMd%2F0UQHI%2BrBPhmBZksJkOfW2AUUh%2BI5lq5sp4PgwjwTQb0sjOVfIQqoMzzRLIetbeVzaHXsJnPysBKiaBOEB%2Bwgll4cJvKJMQRyhY3SmYQAXDxcb3%2BCp51tHs%3D',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212?report_query=eJzVWNtu2zgQ%2FRVCT1vAAexk48Z%2BS92XYtPdbNN2HwxDoCXaJkqJWl6cNQL%2Fe2eoG23JshJsb3kIzOGZOePhZQ79FBhuBAumwVu600SuyLt0y1Ij1Y58YAnlKU%2FXZLkj90rGNjLkM1WcpiYYBNGGKvNxl6EzDDdUz9ASTI2ybBDojXwsDCsqNFqYYJFh8YyqeCaFTVIdTOdPQUoTjFEwYGQZo%2BGdYckc%2F5G3TEeKZ4bLdAHzMTW0IDbsP%2FQQluv3NMsgWTAud2FWBNsPqvhe5nn8bWU4F7BEetEe%2FvhURyrYQv3F9ojmo72Ib6hy4RpRl9VE38ilhxe9XL8HQ43VLamX9v7FLX1aWFyABofJrc9gcB7%2BGrI0lqqOnI%2FJnzjbK%2FA2D%2BCF%2FJRyQ2ZSNzdGaGEqjPIpL3ZklWJptDu1TTw%2Ff8MwIfAs3SseebVx2Kyw9SHxHLzo9an920IK3OxqivltZCwVpIk5Okwxi3hCRYPypP9hBpFM8Au2JMCLufDf5xE3%2FHzCmUwSbuA%2BaWOMysnnUjYd28v8mQrLumqMW2rRc01bnX3eD8xQLhqkvrkv10Gozh1E7pmKmH9jwkIWoKo6YZaDjuizyvV4RU8F6ChzZyJbRLw4iwNvP4X3sAhWG5mQOxlR7Dzkt2byr%2BqUSti8%2FLAIM76VJuw4fgUC6MM2vgdAs2r8qt8G%2Fg55%2BLfaRmZEfJcKHVH9iOKcScGvSyofyQwP0T9UsY20mn3j6rQR%2FpAa9UlkvxgECaPaKpcJSM9QrkJts0zgjRXzhKUakEXTroQaKA4FqoC7Gl8OL3%2B%2FGN5cjG5gBvr6oX1yMRpjKLaiVphQ0XSNk1fDGKwrLgxToD4hjzx16dRlLmUzqmhyoE1h4R%2FJR5nVy2VklsLI3R%2BADG6FCBbFuCiwhnqKE7qEa%2BOutpyFx8H0ejwI7uiSCUBdAyS%2FoPEzbiuHeF0jRkMPAoMKc1NjLn3MpYeZeEw%2B5rrGjIc1Br9aDcIRLp%2BWyvylYqxiMME%2F%2FFqSxm4TOcXvUIXmDxXLwAFU0S%2Bk%2B1MrxP8j%2BX3RfKj6y2dXdTcQVT67vHYHF3L0JcRz0lPGHB2pn1MpVrBvLRVvt0zRNSOo9TV5kCImcFURKJLHTLdrqDAXcDuDKtEA6kldO6I21871%2BIVRsAqqDXmNxN4TbF67hQgIR489mSsN5ft2UV8Nz3HD%2FdiTvMWzi3p8lnrcoE5tsmTqLPO4m3lylnnyUuZJN%2FPo5iw1QF5acHTtXOzzJb9q1rz3cp%2Bu%2Bh1fMdeLvQsM%2FartKgpAT%2Bp2Z9eFMjjWRe%2BGpvLkxsF0NAg49A8N74Z7Z7ge5g3rzQ57detRL1oathzA4G9YYFkraTPnVA6KedQvIJMfpPf7VgI6g3uW%2FaDuEmwNisaE2FohlYiKyAqKfTFhBt7yOg8JVxhoD65lCukZyPtpv%2F8Kp9PZkg%3D%3D',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291?report_query=eJzNWG1v2zYQ%2FisCPwwr4ABOtmSJv6XugA1rt2xuuw%2BGIdAUY3OjSI0vTo3A%2F31HipKoF7vysKXLh0A8PnfP8UjeHf2MDDOcohn6UeyoMFLtk1zuaA7fyXqf7LBiWBg0QWSLlXm%2FLxwWhlus506CZkZZOkF6K5%2BC4BFz7SSUU2JoNscqm0tuc6HRbPmMBM6djQclM0u8ZZl5BwzNl%2B5f8oZqolhhmBQrmM%2BwwYHY0E9Og1um3%2BGiYGIDwvU%2BLYKxw6S2%2F7HxvLTfLOVzBitkZG3x04fGUmBL9Z92hLUYHVl8jZU317O6rifGWq40IushusnCYGP1gOuVfHxwK50BFm%2Bgx2FK6RkMXiPeQyoyqRrL5Tj52c2OMrwrDUQmPwhmkrnU%2FYORWphKSTkV2SZWKSrI%2FtgxifTiA0M5B1jyoBiJYuOxRZCNIYkUIuvNZf3VggvM7BuK5T0xFvOkj%2BlcpowSlmPeozyq3%2FaAyNwtcMABFubSv84j7unFhHOZ58xAPhliJNXkuZR9xeEwf8Tc0lMxdkdqNXJPB5Vj3t%2BowYz3SGPxWK6WqZMnKHmgitA4Y8JGBlAdnbQoQR36olbt7ugxAyfCfNKRnUP8Yy9a2rEL72ATrDYyT95Kgl3lSb7uO%2F%2BqcamCLauPVVqwnTTpiesXEECfDvEtAE3r8atxB%2FgF%2FIiz2lYWCX%2BRCHWovkRwPuNCHBchn5K5u0S%2FY0W30mr6H0dniPCLxGiMI4fVBOUUa6to6zpWnabzlMGHBnQo3HWzhgXme8OIDut4D3vyA9tsYQoaEgVNA%2FNbcDW9%2BvZientxeQszUPbb8ruLyxvHQh%2Bx5SZVWGzc5DfTDKSPjBuqoDkFN8uVSd98lp1ugRXOW60rnIunBPxodtPIQsDIpxdAonvO0SqMg98aws2PtC1MG5%2F5ShaWodn1zQS9xWvKAXUNkDJ%2Fu2936jziuwZxOY0gMKgxtw3mKsZcRZi7iCnGXDeYm2mDcUtrQG7kdldLZX5RmYsiunN%2FblkSZ%2F6M%2BQeBR4UnQapoAQrQNPWeBW9wVNcLqIuuH4CQdSIXJJ1%2BOIb329T%2F4VNDWM7%2FnVdG3Ke3Hxrty3gqA42jqRJi6zFDN0yII%2B3gupo8tznrK3Y7eZ0sJM8iLtcu61SDcCRHo9C3Db2TVYLG9lUQnbuUnt4QG6FsB%2F3tV8l99gcU5RZx3T8ZyF36kUK7N3aJQ6ox%2FfciO7Jz1M%2BcudbOmfYPLtJ6cC3rF9PYLvacJ9ayJR%2FJ0HF67mFm6A30Iu8sV4jwhoY6BAny2Y%2FR7HKCGOQtDS3ygxdcT8vk%2B3pf1i%2F37ZIajHzaBdFGSVtU834QAK4qwzlbyOhXmxzKI4skh0mTiOjGFevUVQRgJZgTy7FL5zk1EGtdmoQnFZRMpqUoE%2FHs%2BXD4GzZEbLE%3D',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350?report_query=eJztV01vGjEQ%2FSsrH3oiEtCSJtyStIeoiZqWpBcUrcyuAbdee%2BsPWoT475nxevHCQgVSj%2BQQ4Zk388Yzg2dYEcutYGRIHpW08%2BSzzJN7uWDSKr1MvjkqQb9M3iU%2FqHAsmSyTJ61yl1kQaA5a0iHZnGr7vCzRCxzn1NyhhAytdqxDzFz9CYIpFQYlTLDMsvyO6vxOCVdIQ4bjFZG0QB%2BBAT2rHAX3lhVj%2FJd8YibTvLRcyVfQ59TSQGzZX7QQjptHWpZczkA4WaZlcLbubPyHyJNnf%2FMNy2JzoSPcml%2Bu6XL05SU6CpQec0qMLadQA5Y8qIzifaP%2FWjKuPxyZClF7anDcshmXEiCbWkee8aRWpr%2BDcocpZxkvqGiTtQybnC%2BSW5OMlMgbXA6FqQHhkRzRoO37O8sYX7Ac%2BvYm%2F%2BkM9FqDqo4ptZpKM2Vas2NJ95nuo7dOyy1OHUSnZrJl12SDL%2BuByjGvOZFMOiF2WwP9x%2FfAPwJ7G4TXmDRTxu7wZQ7yJLPlP%2Fpk235%2Ft7ToEZqq6b6uOUjZtDlcuYNUVT1OJausjurTFnPMTN1z6QIhx8ZwyH5PIx0udOin6OuUEHb6KpBBqikXh5i0155O89ohBaPGaUTOuYFgeUZFjBx98YJJgw%2Bhfxbju28sTKnUch9mv9v%2FcNG9uuhdgQai2pZfX%2FQu0RWbUidsCpmdofJ9F7%2F0Uy4s0zDSIJqSLxT0gB9Z1XwsqabF1sAbwYRMnlUZ02BViQ9%2BVajhmNwIQV7DOaTBwKX9%2BNrz8sO9fV4rFp6T4eCyQx7ohAlADQBSpR0%2FY2U84mNE9LoNCBw2mKuI6Tcx%2FQbmusHUxAwi5rIbMXi1CMITFtEobb%2FqHLNIrvEPr6Vo7ivt1wiPCosEtEsJBvB2nJeJ8zJxXibOy8R5mTgvE%2F9jmSjpjIXpDWNl5c9k2OsQDhPEPDH95AWDbjWybpfV1MfPOF7g5IcViGZaubLW%2B0MA4MoC1Rqpxi%2FkApYK3pCs4U5UZE5QnHYFs7DVmMo2UwVsFNwomcK9IJbVev0G7KN7aQ%3D%3D',
            },
            {
              title: 'Inventory value',
              link: '/reports/595?report_query=eJztV99vGjEM%2FldQnnloK9GtvHX0ZdqmdWu7F1SdQs6l0XJJljhsCPG%2Fz7kLd%2BH4UbZneEDE%2FuzPdhxbrBhKVMDG7KNegEbjloMfXAUYzJaDe2fKIJAETnKNng2ZeOUOH5c2WtDxlftJlLAxugBD5l%2FN7yR44cpHCSgQCOWEu3JiVKi0Z%2BPpimleRR%2BJIno2ZR0GQjWNX4M78MJJi9LoZ9KXHHkiRvgTLVSQ%2Fgu3Vuo5CWfLwiZn62HrP4Xe%2BV%2B0grccbpCZt4dPT52nxFb4n%2BEEbzk689ireud9eiswcDXoABPjsVcIEZwDLZY7bHuNc97vgFyqHdJcfCrXlqu9qX0LVEeJ1FDgBOS3MZUbUPErgQrbgHr0tjXdZj%2Fs4EiZjwayiIj%2FjmLLOg%2FhSUtsrqHfi0UgVSEa1SkV37XLexSUIhg9Ximyq62xNslOIckMjl7qsZbdYHpVLEHIiqu3u7bl2IpAmComuCcAmXRtL5xIvGOXE05MVUmkEbaPUWyU%2F0q5a8jWz0NWAffBwcEnTL5lBdrTUGxmXjbP4IUHhYXjep7G84tUCI7mLXm2cmGwMPU8bbSWO15tTeMHGt%2BDR2O7BNFYTae6qwnJbpViz%2BmcMvSUkOp11WYESo%2F1g2tYZMnGo%2Bsh%2B8xnoAg1IkgzNuLvWPIa8a5DXF5kEDq0mPcd5irHXGWYm4wpx4w6zPVFh4mpdaB4ihfijcOvroxVZDfxE9MyvKxvsd5xNSptucKBJQN6lOdNd95050133nTnTXdg01k%2Bh7SOaE6u6jMbXw6ZpJHoqW3va8HoopnBH5Zx%2FfSebZrPcX6SNv4FIcncmWBr%2BOaQ9HG9Bo8PJvt7UtHGlJlkTQlzJYLiMeAKkNrCN7aUB21M6Y0uKGsKbbVe%2FwXiGpaj',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?report_query=eJztVl1r2zAU%2FStGMOjAYUm2tGnethTKYP2AlL6EYlT7xhXIktBHuhDy33ev7NZKs47svXkIuUfnniNZvodsmRdeApuxCy7kJltwCY7lrHzi1t9tDK1g%2BcTdnBA28zZAztyTfu6AFZeOEJBQeqjm3FZzLUOjHJstt0zxhjRura5C6UlZVwT89NAs6Su7AFdaYbzQ6gHXK%2B55Z%2BzhN3XIINwVN0aoGsHHTWE6sV3%2BVj9beO6D6206auFe8OPlX3r%2B4hIFDjx8i%2F6HQ%2BxI9O9BVdr2ym2dXdPqUcLrVmB%2Fyyvhsytua6Gyk0urnSta8Et2Db6IN%2F6591zud3x6cycGbAnq0NzErqKJXekGomPWiZ6gY%2FuOZYNsfnO5eNf4jW0ZrAVVbg5895pS31enxOEVO1a9F0mUcd%2BpaKnro%2FWIy3YPOWuAu2CJ60i%2B0GuwawHPpCMaUA6nob3SipMMvosW3xcRNzAejr8NhtPBaIoreOP7%2BPlgdEoysOJB%2BsJyVdPi12GF6EpIDxYnE%2FdgxFr7QsfJQ0JQ2CIUEM1wy5u9AV7gxGd32vQH99oorNZcBiyX7LuU7KGru%2Bfg8NTynVdXOH9P1NZFVGw2Oc3ZL%2F4IElkTpNy3yvibHn5knPWM0TChYPHKmfacccoZJ5zzxCnlTHrO6bDn0NF6ElV0h05bf2MrepzsnD50LM2reNUxFiOrC8bCgsGGovwIx49w%2FAjHf4Wj4TV0uYRzso01m41yJnAk3C3Y2whMhu0M%2Fti0eUa%2FaV6witOHUG11MC%2FrsegIFMHB%2BYVO%2FsI0GJciQXZ5PyFQYyj7goIBXUsuyyA5TXUD3orStZKlbjA5hdMKY9vjFre73R8EUyGD',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?report_query=eJztVl1r2zAU%2FStGMOjAYUm2dG3etgzKYP2AlL6EYlT5xhXIktBHuhDy33ev7NROso7svXkIvkfnnnNl6V68YUEGBWzK5lyBz1jOxDN34X5tCcTwmfsZIWwaXISc%2BWfz0gJLrjwhoEAEKGfclTOjYq09my42TPOaNO6cKaMIpGxKAn4GqBf0l%2F0AL5y0QRr9iOslD7w1DvCbMlSU%2FppbK3WF4NO6sK3YNj%2FUz%2BaBh%2Bg7m5Za%2BB1%2Buvwu5y8uSeDIIzTofzikjJ7%2BA%2BjSuE65ibMbWj1JeNUI7Je8lCG75q6SOju7csb7ogE%2FZTcQinTkHzvPxX7Gh4MzseAE6GNzm7KKOmX1C0iOWSt6ho5Zc8kG2ez2av6m8YGtiM6BFusj372kvu%2BrU8%2FhFTtVvRPpKWPdfVFhqpP1iMu2jzmrgfvoiOtJvjArcCsJL6Qja9Aeu6E5UiED6eBldHhhZKpgPBx%2FGQwvBqMLXMEj38cvB6Nz0oEljyoUjuuKFj8PS0SXUgVw2JpYhJUrEwqTWg8JUWOK1EA0yx2v9zp4ji2f3Rvb7TwYqzFacRUxXLBvSrHHNm5fhMdtqzfurvThgaiNiyzZdHKes1%2F8CRSyJkh5aJTxmd5%2BYnztGKNhj4LBK%2Bei44z7nHGPc9lz6nMmHed82HFoax2JIjpEb1y4dSW9TnZJP9qW4WU66zQXE6udjIUDiwmFeJ%2BO79PxfTr%2BazpaXkE7l7BPNilm01HOJLaEvwN3l4DJsOnB7%2BtmntEz9QtGqfsQqpyJdreegpZAMzj6MDe9b5gax6XsIdu86xCocCqHggYDugquRFScurqG4KTwjaQwNU5O6Y0ucMNY4ma7%2FQPA3yAL',
            },
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?report_query=eJztVttqGzEQ%2FZVFUEhhTW23TmO%2FtS6kheYCDnkxYVG08lqglYQuaY3xv3dGq2Rluynue%2FxgPKMz58yuZg7eEi%2B85GRG7rQpvotmXSyo5K64tboOzDtSEram1t9tDKIgXFM3xwyZeRt4Sdxa%2F0qJFZUOM1xy5nk9p7aeaxla5chsuSWKtsiRqJFZ15j44Xm7xK%2FiG3fMCuOFVg9wXlNPk7Dnv7FCBuGuqDFCNZB83FQmke3KQ%2F5i4akPrpdJ0Mo950%2Bnf675i0okONLwXfY%2FFGJFxn%2FPVa1tz9zFxTWenkT81BHst7wSvriithGqOLu02rmqS34orrmv4tW%2F7zWX%2BxXvDu7EcMu4OhY3sapqY1XeQFQsEukZKKZhGxTzm8vFq8IHsixYyxXbHOnuFeW6L0qZwkvuVPaeJGOGvnNSppuT%2BRBLdg8laTl1wcar1J7KyqVOa9Fy5WAV9ie9JFRRufGCuSQBq4ubCycwphZGScTexsPxp8HwYjC6gBMYhv38dDA6RxG%2BokH6ylLV4OHHYQ3ZlZCeW1haaM%2BIJ%2B0rHZcSAEFBiVAcYYZa2u7t9gLMoIB%2B%2BnfitVEQPVEZIFySL1KShxSn%2Fh28EPnKVAvn7xHaqYiazCbnJflJH7kE1AQg9x0z%2FMZ7iYjPPWI0zCAQvGAuesw4x4wzzDRTyjGTHnM%2B7DH4aD0II7xep62%2FsTW%2BTjLFDz6WpnWcguiYEZU8s7LcQEHF3nzzzTfffPNfvmlow5MvwZ5sY0xmo5IIWAl3y%2B1tTEyG3Q5%2B3XR%2Bhr9xXyCK2wepxupgns9jkADozsH5hc7%2B3bRglyLL7Mp%2BQ3gDlu0rNAZQZVSyICludcu9BcPuKJluwTmF06qCB4YWt7vdH6CeL1I%3D',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?report_query=eJztVk1PGzEQ%2FSsrn4OUpA2FvUEqVahFpQpwiaKV2R0Sq157ZY%2FTRlH%2Be2e8G9Yk0I9TL%2BSAMs9v3nzYM2QrUKEGkYuZ1OCztc%2BuzBoMWrfJriWWK2WWYiDKlXR4u2mYSeZK%2BikjIkcXYCD8yv7ogEepPSOgoUSoptJVU6tDbbzI51thZM0aN85WocTsqpZLYH1bMaw6s5Iou2h7SAflr2XTcD65aFr%2Foj3dDQ6Fe8krhHrOf7KP4EunGlTWLJ7HQPiJRyEeNkUXJdW%2Fl05Jk%2Bivn4A%2FCJqgdSI0%2B3zXi%2BzL8d%2FDP2UWHRLRr64C57OpDWmKc8twUTJ6ULkJ9QO4owiJQyp%2Fa1HqLL6URP2Ts9636IF6GZwDU26O9JGFCh%2BFEv07o5CUrK4S%2BW%2BB2qtwE%2FGDABWU9AL0kT5Ts71jGqF%2F3E%2BnfaSLEgPVd8z5y6iv%2Br%2Bcwb3UAX4Xfmr94X292tEXncVuMRA1SB8cO8d%2BF9YUK2mqQu2pHEHVYDwNRvu6%2BjftkYa6QBVTHw%2FH70%2BGZyejMzoBkniGn5%2BMTlkKHmXQWDhplnz4bsiX%2Bag00jvL55RQo9YWCxsHkQjBkIsywLRGOlk%2FWxQz2izZrW36PqFtDFnr2L18Li60FovO7rrkqScaXp4k5TE2vo2iKpFPTgfii3wATawJUdp74e98bZHxoWeMhgmFjCfOWc8Zp5xxwjlPIqWcSc85HfYcLq0nscUX6q3DOOiEnfOHy7Kyig8hrt%2FI6hZw4aAhBxrmtyX8toTflvD%2FWcINTUi38mgEt9EW%2BWggFA2FvwF3E4HJsB3vyw1vtsOud7PPI0TH%2FMuLkKWzoYn8vdGd8%2BoPHmc2%2BVVW02ZWCbKjwqUug5a8KWpAp0rf%2Bpa2pm2sPP27oNIpt%2B1u9wtQs3BL',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/profit_margin?report_query=eJztVttuGjEQ%2FZWVpUqptKhASxp4a6iUVm2aSKR5QdHK7A6LVa9t%2BZIWIf49M2bDmqCoyXt4QMz4zDnjyxyxYV54CWzCbrTJvol6lV1bvRQ%2Bu%2BS2FoqiKpTesZyVK279zdoQGsMVd1PKsIm3AXLmVvpvm1hy6SgDEkoP1ZTbaqplaJRjk%2FmGKd4QR0tNzLqixHcPzZy%2Bsq%2FgSiuMF1rd4XrFPW%2BFPfyjChmEu%2BTGCFVjcrEuTEu2zff8t9wKrhL%2B%2B33iP4QqSJkQzX787khaocL9Ca%2FqLBYkpOfcRsYj4sV%2B4RXkj0WJwOE9nlxY7VyxS37IfoEvZlyCe981MD%2BsePfk5A3YEtRxFyZWFU2sShuIio%2FP6QQVs6iY9bLp1cXsWeEnsmWwFlS5PtI9KEp190qJwj73UvaOJGHGvlPSUtcv5iMs297lrAHugoWjg0Ma0YBy%2BOQPXzTOkcepKryIPQz7w0%2B9%2FllvcIYroKrD%2FLg3OCUqWPIgfWG5qmnxY7%2FC7FJIDxZHENsw4l77QscRQ0BQWCIUEMxwy5uDSZ3haGdoEN3evTbU8j2XAcM5%2ByIlu2vj9igcblw%2B84yF87cE3amIik1Gpzn7yRcgETVCyO2OGX%2FT%2BUfE5w4x6CcQDPaYsw4zTDHDBDNOlFLMqMOc9jsMba0DUUTX6LT1V7ai42Rj%2BtC2NK%2FibUf%2Fi6jWAQsLBguK8s0F31zwzQXJY2po3QenYRNjNhnkTODDd9dgr2Ni1N9N2vmaTOb4atpBpEFBAP0PwUxtdTCx4jFo18l9g%2FMznfxHadAmRZLZ5t3bhhoN2RdkCNhHyWUZJKdpbsBbUbodZakbdEzhtCrwCLDpzXb7AKM%2BG%2BE%3D',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },
    {
      section: 'My account',
      title: 'My account',
      icon: 'mdi-account',
      items: [
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        },
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Settings',
          icon: 'mdi-cogs',
          type: 'group',
          items: [
            {
              title: 'My subscription',
              link: '/subscription',
            },
            {
              title: 'Importing status',
              link: '/importing-status',
            },
            {
              title: 'Store setting',
              link: '/settings',
            },
            {
              title: 'Plugins',
              link: '/plugins',
            },
            {
              title: 'User management',
              link: '/users',
            },
            {
              title: 'Logout',
              link: '/logout',
            },
          ],
        }
      ],
    },

  ];

  public FullMenu: any[] = [
    {
      section: 'my-reports',
      title: 'My Reports',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      items: [
        {
          title: 'Key metrics',
          icon: 'mdi-numeric',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales metrics',
              link: '/board/336',
            },
            {
              title: 'Finance metrics',
              link: '/board/335',
            },
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-invoice-clock',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672?report_query=eJztWf9r4zYU%2F1eMYbCx%2BHDcJG3yW9duMHa3dddeYZRiVFtptZMtnyTnGkr%2F9z3JSfSsOFmSwr5Afgn46fO%2BS3pPLy%2BhZprTcBL%2BXM5oqYWcBx9pxWnJ1FMBhLAXZk9E6pt5ZVDw%2BUTUhaGEEy1r2gvVk%2Fi6IEwJV4ZCOc00zS%2BIzC8Er4tShZO7l7AkhZFxJUVeZ1ayyK1qTYs78xNcUpVJVmkmyntYz4kmC8WaPhsOXjP1gVQVKx%2BB%2BDBPq4Ww195K%2Fi2RjJRI%2FmxF%2BDuBSySSdv3LJydpoS1Vn%2Bu9zLMMSOhHKmROZfB7DdqYnjsNd2yZh1TiPHjhyGnGCsLXVG7g7tJ9STRFemVDTkEJ9ZU1yLYmjMfiP5VMq%2BBa8BwJV%2FCZflk4m1ZEkmJHhzo417UZUFCBTznBoSQzKskjBRMZn6e1gaZW3j4GbBeCbTnP%2F6wVbPugPsgoRThVqZa0zN9qGhKFDXxPSR7csIIG34JN6jtkFeyaXMiUAyLVgNjRAp%2BtdXDIlOp5cK1F9hlvBUtOlSED44zyXXfCGmPXrr4SDB%2F91bauDH1HTS0erMTdkR0H9zzTNeHBOmZHrRv52xZkogB8983RrKXuEviynwlbJGAjLmHzBGIa4JJREFYaISjRNlFmoyFxFSelwe2a820ysEkfQHtRF13RcxaBa4fHppu5ZQN53sEG8vwGGzqZu%2BJgT11wIWb2op8rLwpNXDOzbKO7Rwx81q4IbNMOLhyqvYO1y%2FffNpVWY35zsg%2FIfZuxy%2BvNesHww%2FSuMWK9P7FnKDYbtU7N8mF6u1i7LtuLeca7eojM0Pe8bC2PX9phDyl8my8rnql1sAuUf6NntYSil803lsoVY%2Fh63wsLSlQt6Za%2BCaRDWSsVNKRNQ%2Bd6SaWh522q3iRM4mQQxadRPIAVKIotej%2BO4hMjik5JzXUqSfloFk8KIE4Z11RCfwz2VGwmdCps%2F9s027bJaHXPqy7jxpb3VWxwze9BI8troN6F%2FfB%2B8bUIEWfK64TLungAC3p26dZAG4UsDydnvfA9eYBSOwm%2F78fxN4C6bUSHybvYpsvAxgg2bqH678YrVD9GsDMPduZgfQQ79WCnDpYg2MiDjRzsBMGGHmzoYAMEG3iwgYMNEezEg5042AjBEg%2BWONhpK7xtWN%2FBcBbaKIdBKYjaomKUggSlIEo8mEtBglIQnXgwl4IEpSAaeDCXggSlIBp6MJeCBKUgGnkwl4IEpSA69WAuBQlKQXTmwVwKEpSCaOzB%2BvaWUEJqe8sCbRCbcyJIbq8V%2B95FV9aPzxmvzVIgah2JaWTLVZC3SiAspWKaotaGLtnwwS2Ff3IVIOx12%2FHuXD%2B5I5TCXwVyCwQvnR%2Bh%2FP1BFQLN4cv3fTze5vv1k%2Fga3IjKOapFVWKPzjl%2Fm0tDlNIhMtZtoSHKZj%2FGB8XdVUN0mhKMSRAGnaYhxgwdZoSOknHNgczXevDGXfG7d9MSU3mAAarTcWKyPjH5959eqzfO%2Buv5H33qHGdHx9nRcXb0P5wd%2FQcmN8cxyXFMchyTHMck%2B41JKkNqphLQlr7Yb3hx9kIGHai6ovLKEoZx0%2FL%2BMDfd%2FuZmZNEZm84VgOZPRKA8SlFXlnP5sVg3YxoojdcC%2FcFY1FwzRHntuT6TPhotqenQwZ6M8KzmxLTXBdWSZaoRCbcl1GCmRNk0IZOX19e%2FAMDo40Q%3D',
            },
            {
              title: 'Just-in-time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/Max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/608',
            },
          ],
        },
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Top overstocked products',
              link: '/reports/38/demand-forecasting/609?report_query=eJzNmPFr6zYQx%2F8VYxi8sfjNceqkzW95HYOydeuWtPAIwSi2mmpPtjxLzhZK%2FvedbMc6K06bsPfDg1Kw%2FNHd6U4nfZ1XVzHFqTt1FyJ3xJYWUon4C02cvBBJGSvpDtz4hRRqscs1Nn%2B8v5%2F9%2BRlGX4i81S%2FcqSpKOnDli%2FinGXgmXOoRymmsaHJLiuRW8DLNpDtdvroZSbWpJ1IwkintQCR6YNsOJESRxqGi%2F%2BoRXjJ5T%2FKcZRt3mpWc7wetoZ9FQWMilTMnnErnjxKMMLUzhpfPDRFJTUR%2FN8Sq6yqhMUsJt725J2a7KISFUIQ7jxlT0pkLniDfpR6MJAxGLNvSTIliF%2BWcZBlYPzOCN224%2B9XATSmRZaFtGOAQt2bADUtpJpnIgFnvIpNsqaBokWLVQgI%2FuPL8CfzBG5olnfGh7%2FljbYo%2Bk5KrqCDZRr8cpTD4zLiiBS5wW5ffzb76EZKUmF32Vq30boxKTUcVrnO1JbwEaun6Lixa5LQgsFSYtlH9mdS5ydlWgLlc1YsHMCcFSWVvrAtYr%2FMhITv5vQmqjanKBgpjVMVRPTaOJWSb024wWZmuITVQVUGSqqJVh6D9M0v%2BKiW0irMoIOnGcb3jVDPYuh3aXjmT6pRPePWk0Xq9LHGn1wP3V7KmHLAfhr7%2FHVBPtWk3%2BOhX%2B1pjNwi76VDDjzctNfQRdm1h1wYbImxiYRODBQgbW9jYYCOEhRYWGuwKYVcWdmWwEGEjCxsZbIywwMICg0066e1iQ4PhKnQpw6ASeF1TPipBgErgBRZmShCgEngjCzMlCFAJvCsLMyUIUAm80MJMCQJUAm9sYaYEASqBN7EwU4IAlcC7tjBTggCVwLuxsGF1KJxsxTncYw7chqYJlcgz3H0zzs%2Fp%2BsPNddx%2FIVpFiGIzWQvRAoY%2B3humPUO0gQLMBIhBGyjETGiYMdo9emkG0k89uVqZqz0qaC7g9oiPr%2FeHWkCYNN4pmi71P%2BcnKuOCVefxqj9nndsPbqtGjeAr96vJh%2Fkvj8ZI4yiSX8qLIqsmfFuaxAphlooSp8sOgFTvLfdxWcDdE%2B%2Fe819P7vV%2Bd9AjbRKc3yjFCqlXsZyZh3610xfH1xMhl5THnv%2FtiEYkPrYbFIUDosoB%2FYOiIdtNpK39z4DeM9OJ6SCI3gkMBOCGRglhfBehBSPddHZw75vCAbabWWTOC8GabTmLVQmFPd74Z4Zycj72f5fFIgXeYJ2Oql9eeqoczevtpEUtgo96RYtjy5FRof3tUenpt0%2BN6jKyj4zWgNlF1XV88fFlzcex6J3n3AqprL6Ey06efU62E7DlOeVcl%2B6hYDHOZGf8TA%2FWZYbaZb1z5pUskf0HS61ZLj9MGq1z0uknOO5OOl1XLy93Ws%2BrP%2Bd0m9ZfcyA4XqtnkMoDl4G2kA%2B0eKgGQpA1ErTJp53WbG%2Bd6Ksa1KoEUP1rBoxsClHm1dzDQ%2FNef2zD0TQX6JeOFL6GGRrZw9oJj0tOtEZKqYKCynouNBh8fTIpMjhqFMT5ut%2F%2FB7IlzzA%3D',
            },
            {
              title: 'Top understocked products',
              link: '/reports/38/demand-forecasting/609?report_query=eJzNmPFr6zYQx%2F8VYxi8sfjNceq0zW99HYOydeuWtPAIxai2mmpPtjxJzhZK%2F%2FedZCc6q06bsPfDg1Kw%2FNHd6U4nfZ3nUDPNaTgLF6IObquCymCuRf4luCOSkUqrcBTmT0TqxaY22Pz2%2Bvriz88w%2BkTUpXkRzrRs6ChUT%2BKfbuCRcGVGKKe5psUlkcWl4E1ZqXC2fA4rUhpTnQfjQBRmYL0bKIgmnUNN%2FzUjvGHqmtQ1q1bhrGo4fxntDP0sJM2J0sGccKqCPxowwvTGGV4%2BdkSmDJH93RH3fVcFzVlJuO8t3DM7RCEshCYc8se0CuaCF8h3YwYzBYMZq9a00kJuspqTqgLrB0bwpo3w5X4UlpSoRhobDtjGbRhww0paKSYqYB42mUu20lC0TDO7kCROTqL4FP7gDa2K3vg4juKpMUUfScN1Jkm1Mi8nJQw%2BMq6pxAXe1eX3NZXK7Cpa%2FNhusu7pzVoJmJU1hs4sbnK1JrwBahnGISxa1FQSWCpM43o4kyY3NVsLMFfrdvEA1kSSUg3GuoD1Bh8KslHfu6B2MdlsoDAmNg772DlWkG1O%2B8FUTfkAqYGqClLYitoOQfvnovirUdAqwUJC0p3jdsfpbnDndux75UzpfT7h1Z1B2%2FWyIpydjcJfyQPlgP0wjuPvgLprTYfJx9jua4OdI%2By8R40%2Fnu%2BocYywMw87c9gYYaceduqwBGFTD5s6bIKw1MNSh50g7MTDThyWImziYROHTRGWeFjisNNeevvY2GG4Cn3KMagEUd9UjEqQoBJEiYe5EiSoBNHEw1wJElSC6MTDXAkSVIIo9TBXggSVIJp6mCtBgkoQnXqYK0GCShCdeZgrQYJKEJ172NgeCntbcQ73WAC3oWtCLeoKd98F54d0%2Ffbmet1%2FKVpFimJzWUvRAsYx3huuPVO0gRLMJIhBGyjFTOqYKdo9ZmkOMk8Dubp3V3smaS3g9shfX%2B83UhRNjq73K03LpfkX%2FERVLpk9j%2B%2BHc9a7%2FeC2qjtjqE5fTT7Mf7l1RjpHmfrSHBWZnfBtaRIvhItSNDhdfgDEvvfc542EuyffvOe%2FnTzo%2FWqrR3ZJCH6jFCukQcVyYB6G1c5QHF9PhBxTHn%2F%2BtyMakfhYr1AUAYiqAPQPioasV5mx9j8Des9ML6atIHonMBCAK5oVhPFNhhaMdNPBwb1vCge428yiCp4I1mzLi1w3UNjXG%2F%2FAUPbOx%2F6vqlyUwDus11Hty2NPlVfzBjtp0YrgV71ixLHnyKnQ4fawevrtU8NeRv6RsTPgdpG9jo8%2Bvrz5OBaz84JLobTXl3DZqYPPyd0EbHlOOTelu5Esx5nsjR%2FowbvMULs8bIK5lSVq%2BGBpNcvxh0mndfY6%2FQTH3V6nD%2Fbl8U7bee3nnGnT9msOBMezfQapPAoZaAt1Q%2BWNHUhB1ijQJp82RrO9daLft6BRJYBajQNDKyma2k7ePnSA%2BdqGs2ku0E8dJXwOMzTyAosnPG84MSKppBoqqtq50GHw%2BcmUqOCs0RDo88vLf48Gzs0%3D',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },

        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350',
            },
            {
              title: 'Inventory value',
              link: '/reports/595',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?dimension=by_day&title=Daily%20Sales',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?dimension=by_city&title=Sales%20by%20City',
            },
            {
              title: 'Sales by locations',
              link: '/reports/measures/sales_overview?dimension=by_location&title=Sales%20by%20Location',
            },
            {
              title: 'POS sales',
              link: '/reports/measures/total_sales?dimension=by_product&pivot_option=_rows_Location%5BLocation%5D_cols_%5BQuantitySold%5D&params=ShowTop__topn__text__single__All',
            },
          ],
        },
        {
          title: 'Product analytics',
          icon: 'mdi-tshirt-crew',
          type: 'group',
          items: [
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?dimension=by_product&analytic=TopHigh&title=Top%20High%20Sales%20Products',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?dimension=by_variant&title=Sales%20vs%20Inventory%20Matching',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/ProfitMargin?dimension=by_product&analytic=TopHigh&title=Top%20High%20Profit%20Margin%20Products',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },

    {
      section: 'product-features',
      title: 'Product features',
      items: [
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Plugins',
          icon: 'mdi-toy-brick-search',
          type: 'single',
          link: '/plugins',
        },
        {
          title: 'User management',
          icon: 'mdi-account-multiple-plus',
          type: 'single',
          link: '/users',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        }
      ],
    },
    {
      section: 'store-settings',
      title: 'Store settings',
      items: [
        {
          title: 'My subscription',
          icon: 'mdi-account-credit-card',
          type: 'single',
          link: '/subscription',
        },
        {
          title: 'Importing status',
          icon: 'mdi-autorenew',
          type: 'single',
          link: '/importing-status',
        },
        {
          title: 'Store setting',
          icon: 'mdi-cogs',
          type: 'single',
          link: '/settings',
        },
        {
          title: 'Book live demo',
          icon: 'mdi-calendar-account',
          type: 'single',
          link: '/live-demo',
        },
        {
          title: 'Logout',
          icon: 'mdi-logout',
          type: 'single',
          link: '/logout',
        },
      ],
    },
  ];
  public freeMenu: any[] = [
  
  {
    section: 'Analytics',
    title: 'Analytics',
    items: [
    {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
    {
      title: 'Inventory Tracking',
      icon: 'mdi-store',
      type: 'group',
      items: [
        {
          title: 'Inventory tracking overview',
          link: '/board/1460/public',
        },
        {
          title: 'Inventory on-hand',
          link: '/reports/31',
        },
        {
          title: 'Low stock',
          link: '/card/view/1276',
        },
        {
          title: 'Out-of-stock',
          link: '/reports/288',
        },
        {
          title: 'Inventory remaining days',
          link: '/reports/212',
        },
      ],
    },
    {
      title: 'Sales Performance',
      icon: 'mdi-chart-bar',
      type: 'group',
      items: [
      {
              title: 'Sales overview',
              link: '/board/64/public',
      },
      {
        title: 'Daily sales',
        link: '/reports/measures/sales_overview?dimension=by_day&title=Daily%20Sales',
      },
      {
        title: 'Sales by geography',
        link: '/reports/measures/sales_overview?dimension=by_city&title=Sales%20by%20City',
      },
      {
        title: 'Sales by locations',
        link: '/reports/measures/sales_overview?dimension=by_location&title=Sales%20by%20Location',
      },
      {
        title: 'POS sales',
        link: '/reports/measures/total_sales?dimension=by_product&pivot_option=_rows_Location%5BLocation%5D_cols_%5BQuantitySold%5D&params=ShowTop__topn__text__single__All',
      },
    ],
  },
  ],
  },

  {
      section: 'My account',
      title: 'My account',
      icon: 'mdi-account',
      items: [
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        },
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Settings',
          icon: 'mdi-cogs',
          type: 'group',
          items: [
            {
              title: 'My subscription',
              link: '/subscription',
            },
            {
              title: 'Importing status',
              link: '/importing-status',
            },
            {
              title: 'Store setting',
              link: '/settings',
            },
            {
              title: 'Plugins',
              link: '/plugins',
            },
            {
              title: 'User management',
              link: '/users',
            },
            {
              title: 'Logout',
              link: '/logout',
            },
          ],
        }
      ],
    },
    
  ];

  public demoStoreName: string = 'assisty-demo.myshopify.com';

  public get avatarColor() {
    const s = this.store.information.name[0].toUpperCase();
    const value = s.charCodeAt(0);
    return avatarColor[value % avatarColor.length];
  }

  public get isTestingStore() {
    const testingStores = [
      'dariustest2.myshopify.com',
      'the-best-mega-store.myshopify.com',
      '2dba1f-4.myshopify.com',
    ];
    return testingStores.includes(this.store.shopName);
  }

  public created() {
    //this.checkIsMenuPinned();
  }
  public checkIsMenuPinned() {
    //get isPinMenu from local storage
    let isPinMenu = localStorage.getItem("isPinMenu") || "false";
    if (isPinMenu === "true") {
      this.canExpandOnHover = false;
      this.mini = false;
    } else {
      this.canExpandOnHover = true;
      this.mini = true;
    }
  }
  public searchReport() {
    this.$router.push('/reports/0/all-categories?keyword=' + this.search);
    this.search = '';
  }

  public doLogout() {
    this.$emit('logout');
  }

}
